import React from "react";
import Grid from "@material-ui/core/Grid";

export default function errorMessage({ ...props }) {
  console.log("errormessage props", props);
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: props.height ? props.height : "100%" }}
    >
      <div
        style={{
          color: "#f53232d6",
          fontSize: "1.3em",
          fontWeight: "bold",
          ...props.style,
        }}
      >
        {props.errorMessage.message}
      </div>
    </Grid>
  );
}
