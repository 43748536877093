import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import Container from "../components/container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import queryString from "query-string";
const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    marginTop: "2.5em",
  },
}));
export default function SignIn() {
  const history = useHistory();
  const classes = useStyles();
  const { locale } = React.useContext(LocaleContext);
  const parsed = queryString.parse(history.location.search);
  const [basket, setBasket] = React.useState(
    parsed && String(parsed.interaction) == "1"
      ? process.env.PUBLIC_URL + "/1.jpeg"
      : process.env.PUBLIC_URL + "/0.jpeg"
  );

  history.listen((location) => {
    const parsed = queryString.parse(history.location.search);
    setBasket(
      parsed && String(parsed.interaction) == "1"
        ? process.env.PUBLIC_URL + "/1.jpeg"
        : process.env.PUBLIC_URL + "/0.jpeg"
    );
  });
  console.log(basket);

  var backButtonPrevented = false;

  function popStateListener(event) {
    if (backButtonPrevented === false) {
      // Nope, go back to your page
      history.go(1);
      //backButtonPrevented = true;
    } else {
      window.removeEventListener("popstate", popStateListener);
    }
  }

  window.addEventListener("popstate", popStateListener);
  return (
    <AppLayout title={Languages[locale].inShop.title} center>
      <Container className={classes.center}>
        <Typography variant="h5" component="p">
          {Languages[locale].inShop.text.p1}
        </Typography>
        <Typography variant="body1" component="p">
          {Languages[locale].inShop.text.p2}
        </Typography>
        <Typography variant="body1" component="p">
          {Languages[locale].inShop.text.p3}
        </Typography>
        <img
          src={basket}
          alt="shop"
          style={{ margin: "auto", marginTop: "8vh", width: "100%" }}
        />
      </Container>
    </AppLayout>
  );
}
