import React, { useEffect } from "react";
import "../styles/profile.css";
import AppLayout from "./layout";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import "../styles/events.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PaymentIcon from "@material-ui/icons/Payment";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";

import Box from "@material-ui/core/Box";
import ProfileForm from "../components/profileForm";
import Payments from "../components/payments";
import CaseList from "../components/casesList";
import AuthService from "../services/auth-service";
import BackButton from "../components/backButton";
import queryString from "query-string";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "60px",
    height: "60px",
    marginTop: "30px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  h: {
    background: `${theme.palette.primary.main} !important`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1,
  },
  submit: {
    margin: "10px",
  },
  appWrapp: {
    width: "100%",
    height: "100%",
    margin: "15vh auto 10px",
    cursor:
      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZZJREFUeNq8VtFxgzAMBU/ACOkGZIKYDZK/XL+aCRImwZ6AfvXyl24QMgHeoBnBI1TinnuqC2kDKbrTcRek9yTZeiRNbth2+5zRQ5OvyPPotSO/kDfH45sfwkhvAB/I9+QZfm6iMI0ng1ty00eU9oBzpSfyBUAtJb4PFLJGEUx2Jd9QrBskoIQXetSoaheAQapFNx6jcYKoxnvOe/1BAJAWs+VKrvQbg1Y985fnUFJsQ7ELdM6xy0Ceipm3qGAJ8Arn8Bfj+ZcgadEh43iFgANmvhsB3uVzDucyBrC6/BTVf3C7FFBgLOdknBUY1xmjelLi8CyCqmS8hVwLTK2wRAnfGBx0PoEgZwxxrVcKgE20PFNMi8XMVfQyewDBNwyV/LPFBP4BmD4mcNHcppo8T6cguZ2eYL3dBHDeJQdtYrsoMHqoIls5gSDk7oMgKmg4L4ZGF0xoRoAbbPEa47FSiwz0vGbBYuG6k0SKXQ0s83WL0MUGd/gkSIpfzsRBf0oh1xnk3s/7wZnlkznLR//evy1D3QX7FGAAhvHiHphAQV8AAAAASUVORK5CYII='), auto",
    padding: "10px 0 0 10px",
    position: "relative",
    left: "-10px",
    top: -"10px",
  },
}));

export default function Profile({ children, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  const [value, setValue] = React.useState(
    parsed && parsed.value ? parseInt(parsed.value) : 0
  );
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const img = null;

  useEffect(() => {
    let user = AuthService.getCurrentUser();

    if (user) {
      if (user.user) user = user.user;
      setName(user.username);
      setEmail(user.email);
      setPhone(user.phone);
      //setImg(user.img);
    } else {
      history.push("/signin");
    }
  }, [history]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push({
      pathname: "/profile",
      search: "?value=" + newValue,
    });
  };

  const logout = () => {
    history.push("/logout");
  };

  const onChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      default:
        setName(event.target.value);
    }
  };

  let letters = "";
  if (name)
    name.split(" ").forEach((name) => {
      letters = letters + name.charAt(0);
    });

  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <div>
        <form
          action="https://demo-ipg.ctdev.comtrust.ae/PaymentEx/MerchantPay/Payment?lang=en&layout=C0STCBVLEI"
          method="post"
        >
          <input type="Hidden" name="TransactionID" value="282117514150" />
          <input type="submit" value="Submit" />
        </form>
      </div>
      <div>
        <div className="">
          <div className="app-container">
            {/* @main page view */}
            <div className="view-main">
              <BackButton
                history={history}
                location={"/home/"}
                style={{ color: "white" }}
              />
              <div className={[classes.h, "header-wrapp"].join(" ")}>
                <div className="app-detail">
                  <Avatar className={classes.avatar}>
                    {img ? <img alt={name} src={img} /> : letters.toUpperCase()}
                  </Avatar>
                  <div className="header__address">{name}</div>
                  <div className="status">online</div>
                </div>
                <div className="wave-wrapp">
                  <svg
                    className="wave"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                  >
                    <defs>
                      <path
                        id="gentle-wave"
                        d="m -160,44.4 c 30,0 58,-18 87.7,-18 30.3,0 58.3,18 87.3,18 30,0 58,-18 88,-18 30,0 58,18 88,18 l 0,34.5 -351,0 z"
                      />
                    </defs>
                    <g className="parallax">
                      <use
                        xlinkHref="#gentle-wave"
                        x={50}
                        y={0}
                        fill="rgba(255, 255, 255, 0.48)"
                      />
                      <use
                        xlinkHref="#gentle-wave"
                        x={50}
                        y={3}
                        fill="rgba(255, 255, 255, 0.78)"
                      />
                      <use
                        xlinkHref="#gentle-wave"
                        x={50}
                        y={6}
                        fill="rgba(255, 255, 255, 0.8)"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab icon={<PersonPinIcon />} {...a11yProps(0)} />
                <Tab icon={<PaymentIcon />} {...a11yProps(1)} />
                <Tab icon={<HelpIcon />} {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <ProfileForm
                  name={name}
                  email={email}
                  phone={phone}
                  onChange={onChange}
                  logout={logout}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Payments />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CaseList />
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
