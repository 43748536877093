import React, { useEffect } from "react";
import AppLayout from "./layout";
import Container from "../components/container";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth-service";
import BuyByeService from "../services/buybye-service";
import moment from "moment";
import Loader from "./loader";
import LOGO from "../components/logo";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const useStyles = makeStyles((theme) => ({
  cardNumber: {
    marginBottom: "0px",
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-around",
  },
  marginBottom: {
    marginBottom: "12vh",
  },
  mt1: {
    margin: theme.spacing(0.5),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));
const Address = ({ data }) => (
  <div>
    <address className="header__address">
      <i className="fas fa-map-marker-alt" />
      {data.subtitle}
    </address>
  </div>
);
const HeaderTitle = ({ data }) => (
  <h1 className="header__title">{data.title}</h1>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
  </div>
);
const HeaderItem = ({ data }) => {
  return (
    <div className="header__item">
      <HeaderTop data={data} />
      <Address data={data} />
    </div>
  );
};
const RecieptsCard = ({ classes, data, id }) => {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const onChange = (prop) => (event) => {
    console.log(prop);
    history.push("/receipts/" + prop);
    //setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <div className={classes.creditCard} onClick={onChange(id)}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <ShoppingBasketIcon color="primary" />
      </div>
      <div>
        <Typography className={clsx(classes.mt1)} color="primary">
          {Languages[locale].purchaseLabel} : {data.sessionUUID}
        </Typography>
        <Typography className={clsx(classes.mt1)}>
          {moment(data.endDate).format("YYYY/MM/DD")}
        </Typography>
        <Typography className={clsx(classes.mt1)}>
          {moment(data.endDate).format("HH:mm")} &bull; {data.totalProduct}{" "}
          items
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <strong>
          {data.currency} {data.totalValue}
        </strong>
        <ChevronRightIcon />
      </div>
    </div>
  );
};
const Reciepts = (props) => {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [cards, setCards] = React.useState(undefined);
  //const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      console.log(user);
    } else {
      history.push("/signin");
      return;
    }
    async function main() {
      let res = await BuyByeService.getUserShops();
      if (res.success) {
        console.log(res.result);

        function dynamicSort(property) {
          var sortOrder = 1;
          if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
          }
          return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            var result =
              a[property] < b[property]
                ? -1
                : a[property] > b[property]
                ? 1
                : 0;
            return result * sortOrder;
          };
        }

        let sorted = res.result.sort(dynamicSort("-endDate"));

        setCards(sorted);
      }
    }
    main();
  }, [history]);

  const classes = useStyles();
  if (!cards) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  return (
    <AppLayout title={<LOGO></LOGO>} bottomNav style={{ overflowY: "scroll" }}>
      <div className={clsx(classes.marginBottom)}>
        <Container className="Header">
          <HeaderItem
            data={{ subtitle: Languages[locale].purchaseList }}
          ></HeaderItem>
        </Container>
        <div className={(classes.mt1, classes.marginTop)}>
          {cards.map((card) => (
            <RecieptsCard
              key={card._id + Math.floor(Math.random() * (125 - 5) + 5)}
              id={card._id}
              classes={classes}
              data={card}
            />
          ))}
        </div>
      </div>
    </AppLayout>
  );
};
export default Reciepts;
