import AuthService from "./auth-service";
import queryString from "query-string";
const fetch = AuthService.fetch;
const encryptData = AuthService.encryptData;

const timeDifference = (date1, date2) => {
  var difference = date2.getTime() - date1.getTime();

  var minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  return minutesDifference;
};

const buybyeURL = () => {
  return "https://buybye.reckon.ai";
};

const getToken = (token = "authToken") => {
  return JSON.parse(localStorage.getItem(token));
};

const setToken = (token, data) => {
  localStorage.setItem(token, JSON.stringify({ ...data, time: new Date() }));
  return true;
};

const deleteToken = (token) => {
  localStorage.removeItem(token);
};

const validateLocal = async (token, expiration = 60) => {
  const _token = await getToken(token);
  let diff = _token
    ? timeDifference(new Date(_token.time), new Date())
    : undefined;
  console.log(_token);
  console.log(diff);
  if (_token && diff !== undefined && _token.success && diff < expiration) {
    console.log("im valid");
    return _token;
  } else {
    return undefined;
  }
};

const requestWrapper = async (method, url, data) => {
  let authToken = await getToken();
  let diff = authToken
    ? timeDifference(new Date(authToken.time), new Date())
    : 9999;
  if (authToken && diff < 8 && authToken.user) {
    let token = authToken.token;
    console.log(url + "?" + queryString.stringify({ _a: "a", ...data }));
    let result = await fetch(
      method,
      url + "?" + queryString.stringify({ _a: "a", ...data }),
      { _a: "a", ...data },
      {
        authtoken: token,
      }
    );
    return result.data;
  } else {
    deleteToken("authToken");

    let user = AuthService.getCurrentUser();
    let aux = {
      clientID: user ? user._id || user.idUser : null,
    };
    if (!user) {
      delete aux.clientID;
      delete aux.user;
    }
    let resEncrypt = await encryptData({
      dns: "buybye.reckon.ai",
      data: aux,
    });

    setToken("authToken", {
      token: `${resEncrypt.data.result}`,
      user: user ? true : false,
    });
    console.log(url + "?" + queryString.stringify({ _a: "a", ...data }));
    let result = await fetch(
      method,
      url + "?" + queryString.stringify({ _a: "a", ...data }),
      { _a: "a", ...data },
      {
        authtoken: resEncrypt.data.result,
      }
    );

    return result.data;
  }
};

const openBuyByeToShop = async (machine) => {
  let result = await requestWrapper(
    "PUT",
    `${buybyeURL()}/app/OpenBuyByeToShop/`,
    { machineCode: `${machine}`, payment: getPaymentSystem() }
  );
  if (result.success) deleteToken("shops");
  return result;
};

const getUserShops = async () => {
  let result = await requestWrapper("get", `${buybyeURL()}/app/shops/`, {});
  setToken("shops", result);
  return result;
};

const getUserShop = async (id) => {
  let result = await requestWrapper("get", `${buybyeURL()}/app/shop/`, {
    idShop: id,
  });

  return result;
};

const getProducts = async () => {
  const p = await validateLocal("products", 60);
  if (p) {
    console.log(p);
    return p;
  } else {
    let result = await requestWrapper("get", `${buybyeURL()}/app/products/`, {
      active: true,
      limit: 10,
    });
    setToken("products", result);
    console.log(result);
    return result;
  }
};

const getProductsMachine = async (machine) => {
  let result = await requestWrapper(
    "get",
    `${buybyeURL()}/app/products/machine`,
    { machineCode: `${parseInt(machine)}` }
  );
  return result;
};

const getCustomerCards = async () => {
  const p = await validateLocal("cards", 60);
  if (p) {
    return p;
  } else {
    let result = await requestWrapper(
      "get",
      `${buybyeURL()}/app/customer/card`,
      { payment: getPaymentSystem() }
    );

    setToken("cards", result);
    return result;
  }
};
const createCustomerCard = async (card) => {
  deleteToken("cards");
  console.log(card);
  let result = await requestWrapper(
    "post",
    `${buybyeURL()}/app/customer/card`,
    { card, payment: getPaymentSystem() }
  );

  return result;
};
const setCustomerDefaultCard = async (card) => {
  deleteToken("cards");
  let result = await requestWrapper(
    "put",
    `${buybyeURL()}/app/customer/change/card`,
    { card, payment: getPaymentSystem() }
  );

  return result;
};

const deleteCustomerCard = async (card) => {
  deleteToken("cards");
  let result = await requestWrapper(
    "delete",
    `${buybyeURL()}/app/customer/card`,
    { card, payment: getPaymentSystem() }
  );

  console.log("delete customer card", result);
  return result;
};

const customerPayDebt = async () => {
  let result = await requestWrapper(
    "post",
    `${buybyeURL()}/app/customer/pay/debt`,
    { payment: getPaymentSystem() }
  );

  console.log("customer pay debt", result);
  return result;
};

const createCase = async (props) => {
  deleteToken("cases");
  let result = await requestWrapper(
    "POST",
    `${buybyeURL()}/app/caseManagement`,
    { ...props }
  );

  console.log("create case", result);
  return result;
};

const updateCase = async (props) => {
  let result = await requestWrapper(
    "put",
    `${buybyeURL()}/app/caseManagement`,
    { ...props }
  );

  console.log("update case", result);
  return result;
};

const getCase = async (id) => {
  let result = await requestWrapper(
    "get",
    `${buybyeURL()}/app/caseManagement`,
    { caseManagement: id }
  );

  console.log("get case", result);
  return result;
};

const getUserCases = async (props) => {
  const p = await validateLocal("cases", 60);
  if (p) {
    return p;
  } else {
    let result = await requestWrapper(
      "get",
      `${buybyeURL()}/app/caseManagement/requester`,
      { ...props }
    );
    setToken("cases", result);
    console.log("get cases", result);
    return result;
  }
};

const getAllDocuments = async () => {
  let result = await requestWrapper("get", `${buybyeURL()}/app/documents`, {
    active: false,
    title: "terms",
  });

  console.log("get all documents", result);
  return result;
};

const getCustomer = async () => {
  let result = await requestWrapper("get", `${buybyeURL()}/app/customer`, {
    payment: getPaymentSystem(),
  });

  console.log("getCustomer", result);
  return result;
};

const getPaymentSystem = () => {
  return "stripe";
};

export default {
  setToken,
  getToken,
  getUserCases,
  updateCase,
  getCase,
  createCase,
  openBuyByeToShop,
  getCustomerCards,
  createCustomerCard,
  getUserShops,
  getUserShop,
  getProducts,
  customerPayDebt,
  deleteCustomerCard,
  setCustomerDefaultCard,
  getProductsMachine,
  getCustomer,
  getAllDocuments,
  getPaymentSystem,
};
