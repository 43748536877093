import React from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Flag from "react-world-flags";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import BuyByeService from "../services/buybye-service";
import _config from "../config/config";

console.log(Languages);
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "95%",
  },
}));
function FormControlLabelPlacement({ ...props }) {
  return (
    <FormControl className={props.className} component="fieldset">
      {props.label ? (
        <FormLabel component="legend" style={{ fontSize: "0.8rem" }}>
          {Languages[props.locale].global.language}
        </FormLabel>
      ) : (
        ""
      )}

      <RadioGroup
        row
        aria-label="position"
        name="mobile"
        defaultValue={props.value}
        style={{ justifyContent: "space-evenly", margin: "1em" }}
      >
        <FormControlLabel
          value="pt"
          onClick={props.onClick("pt")}
          control={<Radio color="primary" />}
          label={
            <p>
              <Flag code={"pt"} height="16" />
              {Languages[props.locale].global.pt}
            </p>
          }
        />

        <FormControlLabel
          value="en"
          onClick={props.onClick("en")}
          control={<Radio color="primary" />}
          label={
            <p>
              <Flag code={"gb"} height="16" />
              {Languages[props.locale].global.en}
            </p>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}

export default function ChooseLocale(props) {
  const classes = useStyles();
  const { locale, setLocale } = React.useContext(LocaleContext);
  const handleChange = (prop) => (event) => {
    console.log("value", event.target.value, prop);
    BuyByeService.setToken("locale", { locale: prop });
    setLocale(prop);
  };
  if (!_config.useLocale) return <></>;
  return (
    <div>
      <FormControlLabelPlacement
        className={clsx(classes.margin, classes.textField)}
        onClick={handleChange}
        label={props.label}
        value={locale}
        locale={props.locale}
      ></FormControlLabelPlacement>
    </div>
  );
}
